<template>
  <v-app id="inspire">
    <v-app-bar :style="{ background: appBarColor, color: 'white' }" :elevation="1" scroll-behavior="collapse "
      scroll-threshold="100">
      <v-toolbar-title style="font-weight: bold;">网站建设中--崇左广深软件科技有限公司</v-toolbar-title>
      <template v-slot:append>

        <v-tooltip text="我的消息">
          <template v-slot:activator="{ props }">
            <v-btn icon="mdi-mail" v-bind="props" @click="redirectToLogin"></v-btn>
          </template>
        </v-tooltip>

        <v-tooltip text="夜间模式">
          <template v-slot:activator="{ props }">
            <v-btn icon v-bind="props" @click="toggleTheme">
              <v-icon>{{ themeIcon }}</v-icon>
            </v-btn>
          </template>
        </v-tooltip>

        <v-tooltip text="个人中心">
          <template v-slot:activator="{ props }">
            <v-btn icon="mdi-account" v-bind="props" @click="goToProfile"></v-btn>
          </template>
        </v-tooltip>

        <div>
          <v-btn id="menu-activator" icon="mdi-dots-vertical">
          </v-btn>
          <v-menu activator="#menu-activator">



            <v-card class="mx-auto" min-width="172">
              <div class="flex-column-center ma-5">
                <v-avatar class="ma-3" size="128" rounded="50" v-if="isLoggedIn">
                  <v-img :src="avatar"></v-img>
                </v-avatar>
                <div>
                  <v-chip prepend-icon="mdi-near-me" variant="outlined" color="red">
                    {{ userType }}
                  </v-chip>
                </div>
                <br>
                <div>
                  欢迎：{{ name }}
                </div>

              </div>
              <v-divider></v-divider>
              <v-card-actions class="flex-center">
                <v-btn color="deep-purple-accent-4" @click="goToProfile" v-if="isLoggedIn">
                  个人中心
                </v-btn>
                <v-btn variant="text" color="deep-purple-accent-4" @click="handleLogout">
                  {{ loginoutstatus }}
                </v-btn>
              </v-card-actions>
            </v-card>

          </v-menu>
        </div>
      </template>
    </v-app-bar>



    <v-footer bordercolor="grey" height="44" app>广深软件科技有限公司 | <div>&nbsp; 桂ICP备 2020008666号-3</div></v-footer>



    <!-- 侧边栏 -->
    <v-navigation-drawer floating>
      <div class="d-flex px-2 my-2">
        <v-btn class="flex-grow-1" color="#448AFF" height="40" variant="flat">公司近期动态</v-btn>

        <!-- <v-avatar class="ms-2" color="surface-variant" variant="flat" rounded></v-avatar> -->
      </div>



      <div class="px-2 my-2">
        <v-text-field class="mb-4" density="compact" prepend-inner-icon="mdi-magnify" variant="solo-filled" flat
          hide-details></v-text-field>

        <v-btn class="mb-2" color="blue" height="24" rounded="pill" width="75%">我们入驻扶绥空港啦！</v-btn>
        <br>
        <span>经过深思熟虑，我们最终将公司地址选在了美丽的扶绥县</span>
        <br>
        <span>2023.08</span>

        <v-divider class="my-6"></v-divider>

        <v-btn class="mb-2" color="green" height="24" rounded="pill" width="50%">公司成立了！</v-btn>
        <br>
        <span>经过深思熟虑，我们最终将公司地址选在了美丽的扶绥县</span>
        <br>
        <span>2023.07.17</span>

        <v-divider class="my-6"></v-divider>
      </div>
    </v-navigation-drawer>
    <!-- 侧边栏 -->

    <v-main>
      <v-sheet class="mx-auto pa-2 pt-6" color="grey-lighten-4">
        <v-btn color="primary" height="24" rounded="pill" width="88">公司照片</v-btn>

        <v-slide-group show-arrows>
          <v-slide-group-item v-for="n in 10" :key="n">
            <v-sheet class="ma-3" color="grey-lighten-1" height="200" width="250" rounded></v-sheet>
          </v-slide-group-item>
        </v-slide-group>
      </v-sheet>

      <v-sheet class="mx-auto pa-2 pt-6" color="grey-lighten-2">
        <v-btn color="primary" height="24" rounded="pill" width="88">荣誉墙</v-btn>

        <v-slide-group show-arrows>
          <v-slide-group-item v-for="n in 15" :key="n">
            <v-sheet :width="n === 1 ? 300 : 150" class="ma-3" color="grey-lighten-1" height="200" rounded></v-sheet>
          </v-slide-group-item>
        </v-slide-group>

        <v-container fluid>
          <v-row>
            <v-col v-for="n in 24" :key="n" cols="2">
              <v-sheet color="grey-lighten-1" height="200" rounded></v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-main>
  </v-app>
</template>

<script setup>
import { ref, onMounted, } from 'vue';
import { useTheme } from 'vuetify';
const name = ref('游客');
const appBarColor = ('#448aff');
const theme = useTheme();

const themeIcon = ref('mdi-weather-night');

function toggleTheme() {
  theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark';
  themeIcon.value = themeIcon.value === 'mdi-weather-sunset-up' ? 'mdi-weather-night' : 'mdi-weather-sunset-up';
}
</script>