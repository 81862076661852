<template>
    <div class="container">
      <h1 class="headline text-center">如何选择运营商？</h1>
      <p class="text-center">
        从价格、流量、信号、游戏体验四个维度，为您对比移动、联通、电信和广电，帮助您选择最适合的运营商。
      </p>
  
      <!-- 表格对比 -->
      <div class="table-container">
        <table class="comparison-table">
          <thead>
            <tr>
              <th>维度</th>
              <th>移动 CMCC</th>
              <th>联通 UNICOM</th>
              <th>电信 TELECOM</th>
              <th>广电 CBN</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>价格</td>
              <td>比较贵</td>
              <td>超便宜</td>
              <td>比较便宜</td>
              <td>比较便宜</td>
            </tr>
            <tr>
              <td>套餐时长</td>
              <td>通常只有2年，最长不超过4年</td>
              <td>通常也是2-4年优惠期</td>
              <td>1年、2年、20年长期都有</td>
              <td>长期套餐流量较少</td>
            </tr>
            <tr>
              <td>通用流量</td>
              <td>100G左右及更少居多</td>
              <td>80-250G都有</td>
              <td>最高不超过155G</td>
              <td>100-162GB</td>
            </tr>
            <tr>
              <td>信号</td>
              <td>★★★★★</td>
              <td>★★★★☆</td>
              <td>★★★★★</td>
              <td>★★★★☆</td>
            </tr>
            <tr>
              <td>游戏体验</td>
              <td>★★★☆☆</td>
              <td>★★★★☆</td>
              <td>★★★★★</td>
              <td>★★☆☆☆</td>
            </tr>
            <tr>
              <td>常见限速（5G）</td>
              <td>300M</td>
              <td>500M</td>
              <td>500M</td>
              <td>1000M</td>
            </tr>
            <tr>
              <td>一句话总结</td>
              <td>又贵又小气</td>
              <td>流量真心多</td>
              <td>流量少点但信号更好套餐更长</td>
              <td>有移动80%的覆盖范围不适合打游戏</td>
            </tr>
            <tr>
              <td>激活方法</td>
              <td>必须面对面激活</td>
              <td>可以自己激活</td>
              <td>可以自己激活</td>
              <td>面对面之后迟早要去一次营业厅二次实名（会停机叫验证）</td>
            </tr>
          </tbody>
        </table>
      </div>
  
      <!-- 结论 -->
      <div class="conclusion">
        <h2 class="text-center">根据您的需求，选择适合的运营商：</h2>
        <ul>
          <li><strong>预算有限：</strong>选择广电</li>
          <li><strong>注重流量：</strong>选择联通</li>
          <li><strong>信号覆盖：</strong>选择移动/电信</li>
          <li><strong>游戏体验：</strong>选择电信</li>
        </ul>
      </div>
    </div>
  </template>
  
  <style>
  .container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .headline {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .text-center {
    text-align: center;
  }
  
  .table-container {
    overflow-x: auto;
  }
  
  .comparison-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  
  .comparison-table th,
  .comparison-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
  }
  
  .comparison-table th {
    background-color: #448aff;
    color: #fff;
  }
  
  .conclusion {
    margin-top: 20px;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
  }
  
  .conclusion ul {
    list-style: none;
    padding: 0;
  }
  
  .conclusion ul li {
    margin-bottom: 10px;
    font-size: 16px;
  }
  </style>
  