<template>
    <div id="app">
        <v-app>
            <v-container>
                <v-toolbar density="compact">

                    <v-toolbar-title>卡品列表</v-toolbar-title>
                    <v-spacer></v-spacer>

                    <v-tooltip text="夜间模式">
                        <template v-slot:activator="{ props }">
                            <v-btn icon v-bind="props" @click="toggleTheme">
                                <v-icon>{{ themeIcon }}</v-icon>
                            </v-btn>
                        </template>
                    </v-tooltip>

                    <v-tooltip text="输入密码">
                        <template v-slot:activator="{ props }">
                            <v-btn icon v-bind="props" @click="openInput">
                                <v-icon>mdi-key-variant</v-icon>
                            </v-btn>
                        </template>
                    </v-tooltip>

                </v-toolbar>

                <br>

                <div class="mb-3">
                    <label class="form-label">黑名单查询 </label>
                    <a href="http://zggso.com/#/check_black_list" target="_blank">黑名单查询</a>
                    &nbsp;
                </div>
                <!-- 选择地区、运营商下拉框 -->
                <div class="mb-3">
                    <label for="disableAreaFilter" class="form-label" style="color: red;">选择/输入
                        你的收货地区，否则容易发生驳回:（没有则不选）</label>
                    <v-autocomplete v-model="selectedArea" :items="uniqueDisableAreas" item-text="name"
                        item-value="name" label="" clearable @change="filterProducts" variant="outlined"
                        density="compact" hide-no-data :menu-props="{ maxHeight: '300px' }"></v-autocomplete>

                    <v-select v-model="selectedOperator" :items="uniqueOperators" item-text="name" item-value="name"
                        label="" clearable @change="filterProducts" class="mt-3" variant="outlined"
                        density="compact"></v-select>


                    <v-text-field v-model="searchQuery" label="别管我，我要直接搜索！" clearable variant="outlined"
                        density="compact" :menu-props="{ maxHeight: '300px' }">
                    </v-text-field>


                </div>

                <div class="mb-3">
                    <p>筛选结果一共有: {{ filteredProducts.length }} 个</p>
                </div>

                <v-table>
                    <thead>
                        <tr>
                            <th class="text-left">
                                卡品名称
                            </th>
                            <th class="text-left">
                                运营商
                            </th>
                            <th class="text-left">
                                归属地
                            </th>
                            <th class="text-left">
                                详情
                            </th>
                            <th class="text-left">
                                限制年龄
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(product, index) in filteredProducts" :key="product.productID"
                            :class="{ 'table-light': index % 2 === 0, 'table-gray': index % 2 !== 0 }">
                            <td><a :href="generateLink(product.productID)" target="_blank">{{ index + 1 }} {{
                            product.productName
                        }}</a>
                            </td>
                            <td>{{ product.operator }}</td>
                            <td>{{ showInfo ? product.area : '***' }}</td>
                            <td><a @click="showProductDetails($event, product.productID)" href="#">详情</a></td>
                            <td>{{ product.cardAge }}</td>
                        </tr>
                    </tbody>
                </v-table>
                <br><br>
                <v-footer border @click="openDebugInfo">
                    <v-icon color="green">mdi mdi-vuejs</v-icon>App ver.241227
                </v-footer>

                <!-- 调试信息模态框 -->
                <v-dialog v-model="debugInfoVisible" max-width="600">
                    <v-card>
                        <v-card-title style="background-color: #448AFF; color: aliceblue;">
                            调试信息
                            <v-spacer></v-spacer>
                        </v-card-title>
                        <v-card-text>
                            <ul>
                                <li><strong>是否夜间模式:</strong> {{ theme.global.current.value.dark ? "是" : "否" }}</li>
                                <li><strong>浏览器 UA:</strong> {{ userAgent }}</li>
                                <li><strong>是否移动设备:</strong> {{ isMobileDevice ? "是" : "否" }}</li>
                                <li><strong>屏幕分辨率:</strong> {{ resolution }}</li>
                                <li><strong>当前电量:</strong> {{ batteryStatus ? batteryStatus : "无法获取" }}</li>
                            </ul>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="primary" text @click="debugInfoVisible = false">关闭</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- 详情modal -->
                <v-dialog v-model="dialogVisible" max-width="500" persistent id="productDetailModal">
                    <v-card>
                        <v-card-title class="headline" style="background-color: #448aff; color: aliceblue;">
                            产品详情
                        </v-card-title>
                        <v-card-text>
                            <p>名称: {{ productDetails.title }}</p>
                            <p>套餐: {{ productDetails.package }}</p>
                            <!-- <p style="color: orange;">优惠详情: {{ productDetails.discountDetails }}</p> -->
                            <p>快递方式: {{ productDetails.deliveryMethod }}</p>
                            <p>5G支持?: {{ productDetails.supports5G }}</p>
                            <p>激活方法: {{ productDetails.activationMethod }}</p>
                            <p style="color: orange;">限制年龄: {{ productDetails.cardAge }}周岁</p>
                            <p style="color: red;">激活流程: {{ productDetails.activationFlowChart }}</p>
                            <p style="color: red;">合约期: {{ productDetails.contractPeriod }}</p>
                            <p>复机、注销方式: {{ productDetails.restartAndCancellationMethod }}</p>
                            <p>创建时间: {{ productDetails.createTime }}</p>

                        </v-card-text>

                        <v-card-actions>
                            <v-btn color="primary" text @click="dialogVisible = false">关闭</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- 欢迎modal -->
                <v-dialog v-model="welcomeModal" max-width="550">
                    <v-card>
                        <v-card-title style="background-color: #448AFF; color: aliceblue;">
                            <v-icon>mdi mdi-alert-outline</v-icon>
                            公告
                            <v-spacer></v-spacer>
                        </v-card-title>
                        <v-card-text>
                            <p>欢迎访问！</p>
                            <br>
                            <p>因为下单后运营商可能因为各种原因驳回，为了避免干等，切记下单完成后隔天来查单！</p>
                            <a href="https://haokawx.lot-ml.com/Search/Index" target="_blank"> >本列表查单地址< </a>
                                    <br>
                                    <p style="color:red; font-weight: bold;">请先选所在地区，否则驳回概率大大增加！</p>
                                    <br>

                                    <a href="http://zggso.com/#/how_to_select" target="_blank"> >运营商选择指南< </a>

                                            <br>
                                            <v-btn :prepend-icon=themeIcon variant="outlined"
                                                @click="toggleTheme">夜间模式</v-btn>
                                            <br>
                                            <p style="color: blueviolet;">该网站如遇bug请反馈微信：shenyu10085</p>
                        </v-card-text>

                        <v-card-actions>
                            <v-btn color="primary" text @click="welcomeModal = false">关闭</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>


                <!-- 警告框 -->
                <v-dialog v-model="warningModal" max-width="500">
                    <v-card>
                        <v-card-title style="background-color: red; color: aliceblue;">
                            ？
                            <v-spacer></v-spacer>
                        </v-card-title>
                        <v-card-text>
                            <h1>异常行为管控</h1>
                        </v-card-text>

                        <v-card-actions>
                            <v-btn color="primary" text @click="warningModal = false">关闭</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- 密码输入器 -->
                <v-dialog v-model="inputPassword" max-width="500">
                    <v-card>
                        <v-card-title style="background-color: orangered; color: aliceblue;">
                            输入密码
                            <v-spacer></v-spacer>
                        </v-card-title>
                        <v-card-text>
                            输入密码以解锁“归属地”显示
                            <div class="mt-5">
                                <v-text-field clearable label="密码" variant="outlined" id="passwordInput"
                                    v-model="password" type="password" density="compact"></v-text-field>
                            </div>
                        </v-card-text>

                        <v-card-actions>
                            <v-btn color="second" text @click="inputPassword = false">点错了</v-btn>
                            <v-btn color="primary" text @click="toggleShowInfo">确认</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </v-container>
        </v-app>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';
import { useTheme } from 'vuetify';



// 数据
const products = ref([]);
const selectedArea = ref();
const selectedOperator = ref();
const productDetails = ref({});
const scrollPosition = ref(0);
const password = ref('');
const showInfo = ref(false);
const dialogVisible = ref(false);
const welcomeModal = ref(true);
const warningModal = ref(false);
const inputPassword = ref(false);
const searchQuery = ref('');
const theme = useTheme();
const themeIcon = ref('mdi-weather-night');
const debugInfoVisible = ref(false);

const userAgent = navigator.userAgent; // 获取浏览器 UA
const resolution = `${window.screen.width} x ${window.screen.height}`; // 屏幕分辨率
const isMobileDevice = /Mobile|Android|iP(hone|od|ad)|Windows Phone/i.test(userAgent); // 判断是否移动设备
const batteryStatus = ref('');


// 计算属性筛选禁用地区
const uniqueDisableAreas = computed(() => {
    const areas = products.value.flatMap(product => product.disableArea.split(',').map(area => area.trim()));
    return [...new Set(areas)];
});

const uniqueOperators = computed(() => {
    return [...new Set(products.value.map(product => product.operator))];
});

const filteredProducts = computed(() => {
    let filtered = products.value;

    // 筛选条件
    if (selectedArea.value) {
        filtered = filtered.filter(product => !product.disableArea.includes(selectedArea.value));
    }
    if (selectedOperator.value) {
        filtered = filtered.filter(product => product.operator === selectedOperator.value);
    }

    // 搜索条件
    if (searchQuery.value) {
        filtered = filtered.filter(product =>
            product.productName.includes(searchQuery.value) // 根据输入关键词搜索
        );
    }

    return filtered;
});
// 判断是否为夜间
const isNightTime = () => {
    const hour = new Date().getHours();
    return hour >= 18 || hour < 6;
};
// 切换主题
const toggleTheme = () => {
    theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark';
    themeIcon.value = theme.global.current.value.dark ? 'mdi-weather-sunset-up' : 'mdi-weather-night';
};
// 初始化主题
onMounted(() => {
    if (isNightTime()) {
        theme.global.name.value = 'dark'; // 设置为夜间模式
        themeIcon.value = 'mdi-weather-sunset-up';
    } else {
        theme.global.name.value = 'light'; // 设置为白天模式
        themeIcon.value = 'mdi-weather-night';
    }
    getBatteryStatus();
});
// 打开调试信息
const openDebugInfo = async () => {
    debugInfoVisible.value = true;
    await getBatteryStatus();
};
// 获取电量状态
const getBatteryStatus = async () => {
    if (navigator.getBattery) {
        const battery = await navigator.getBattery();
        const level = battery.level * 100; // 转换为百分比
        const charging = battery.charging; // 是否充电
        batteryStatus.value = `${Math.round(level)}% (${charging ? "充电中" : "未充电"})`;
        // 如果电量低于20%且未充电，弹出提示
        if (level < 20 && !charging) {
            alert("你的手机似乎要没电了，快去充电吧！");
        }
    } else {
        batteryStatus.value = "无法获取电量信息";
    }
};
//展示详细信息
const showProductDetails = (event, productID) => {
    event.preventDefault();
    scrollPosition.value = window.scrollY;
    getProductDetails(productID);
};
//获取卡品列表（带拦截）
const fetchProducts = async () => {
    try {
        const response = await axios.get('http://zggso.com:3000/api/list-auto');
        // const response = await axios.get('http://127.0.0.1:3000/api/list');
        products.value = response.data.filter(product => product.flag === true);
    } catch (error) {
        console.error('你的行为被管控');
        warningModal.value = true; // 打开模态框
    }
};
//生成带ID的链接
const generateLink = (productID) => {
    return `https://haokawx.lot-ml.com/h5order/index?pudID=${productID}&userid=612301`; // UID=23fd989c5af7d0fa

};
//从后台请求详细信息
const getProductDetails = async (productID) => {
    try {
        // const response = await axios.get('http://zggso.com:3000/api/list');
        const response = await axios.get(`http://zggso.com:3000/api/list-detail-auto`);
        const productDetail = response.data.find(item => item.productID === productID);
        if (productDetail) {
            productDetails.value = productDetail;
            dialogVisible.value = true; // 打开模态框
        } else {
            console.error(`Product details not found for product ID ${productID}`);
        }
    } catch (error) {
        console.error('Error fetching product details:', error);
    }
};

const filterProducts = () => {
};
//打开输入框
const openInput = () => {
    inputPassword.value = true;
};
//INPUT PASSWD SHOW MORE
const toggleShowInfo = () => {
    if (password.value === String.fromCharCode(49, 57, 49, 51, 49, 53, 52, 53, 50, 57)) {
        showInfo.value = true;
        inputPassword.value = false;
    } else {
        showInfo.value = false;
        selectedArea.value = '';
    }
};

fetchProducts();

</script>

<style>
a {
    text-decoration: none;
}
</style>
