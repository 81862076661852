<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" md="8">
                <v-card>
                    <v-card-title class="headline">黑名单查询</v-card-title>
                    <v-card-text>
                        <v-form @submit.prevent="checkBlacklist" v-model="isValid">
                            <br>
                            <!-- 手机号或身份证号 -->
                            <v-text-field 
                                v-model="formData.number" 
                                label="输入手机号或身份证号"
                                :class="{ 'is-invalid': !formData.number && submitted }" 
                                required 
                                clearable
                                variant="outlined"
                                :rules="[v => !!v || '此字段为必填项']"
                            ></v-text-field>
                            <v-alert v-if="!formData.number && submitted" type="error" dismissible closable class="mt-3">
                                此字段为必填项
                            </v-alert>

                            <!-- 查询类别 -->
                            <p>选择查询类型 </p>
                            <v-select 
                                v-model="formData.type" 
                                :items="items" 
                                item-title="number" 
                                item-value="type"
                                label="查询类别" 
                                persistent-hint 
                                single-line 
                                required 
                                variant="outlined"
                                :rules="[v => !!v || '请选择查询类型']"
                            ></v-select>
                            <v-alert v-if="!formData.type && submitted" type="error" dismissible closable class="mt-3">
                                此字段为必填项
                            </v-alert>

                            <!-- 错误和成功消息 -->
                            <v-alert v-if="errorMessage" type="error" dismissible closable class="mt-3">
                                {{ errorMessage }}
                            </v-alert>
                            <v-alert v-if="successMessage" type="success" dismissible closable class="mt-3">
                                {{ successMessage }}
                            </v-alert>

                            <!-- 提交按钮 -->
                            <v-btn 
                                type="submit" 
                                :style="{ backgroundColor: '#448aff' }" 
                                class="mt-3"
                                :disabled="!isValid"
                            >
                                查询
                            </v-btn>

                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup>
import { useTheme } from 'vuetify';
import { ref , onMounted} from 'vue';
import axios from "axios";

const theme = useTheme();

const items = [
    { number: '手机号', type: '1' },
    { number: '身份证', type: '2' },
]

// 表单数据
const formData = ref({
    number: "",
    type: "",
});

// 状态和消息
const submitted = ref(false);
const errorMessage = ref(null);
const successMessage = ref(null);
const isValid = ref(false);

// 查询黑名单方法
const checkBlacklist = async () => {
    submitted.value = true;
    errorMessage.value = null;
    successMessage.value = null;

    if (!formData.value.number || !formData.value.type) {
        errorMessage.value = "请填写所有必填项。";
        return;
    }

    try {
        const requestData = {
            number: formData.value.number,
            type: formData.value.type,
        };
        
        const response = await axios.post("http://zggso.com:3000/api/check-blacklist", requestData);

        if (response.data.success) {
            successMessage.value = response.data.data.message;
        } else {
            errorMessage.value = response.data.message || "查询失败，请稍后再试。";
        }
    } catch (error) {
        console.error("接口调用失败:", error);
        if (error.response) {
            errorMessage.value = error.response.data.message || "服务器发生错误。";
        } else {
            errorMessage.value = "网络请求失败，请检查网络连接。";
        }
    }
};

// 判断是否为夜间
const isNightTime = () => {
    const hour = new Date().getHours();
    return hour >= 18 || hour < 6;
};

// 初始化主题
onMounted(() => {
    if (isNightTime()) {
        theme.global.name.value = 'dark'; // 设置为夜间模式
    } else {
        theme.global.name.value = 'light'; // 设置为白天模式
    }
});

</script>

<style></style>
